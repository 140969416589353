table,th,td{
	border: 1px solid white;
	font-size: small;
}

.admin-table tr:hover{
   background-color: yellow;
}
.about-page tr:hover{
   background-color: #003366;
}

html, body, #app, #app>div, .body, #root, .App, .main-container, .filters-list{
	height: 100%;
}

.about-link{
	margin-left: auto;
	color: white
}

.about-link:hover{
	color: red;
}

.about-page{
	width:100%;
	height:100%;
}

.App-link {
  color: #61dafb;
}

.admin-suggest-link{
	flex: 1;
}
.admin-title-container{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 3em;
	width: 100%;
	margin: 0;
	position: fixed;
	top: 0;
}

.admin-title{
	font-size: 2em;
	font-weight: bold;
}

.right{
	flex: 1;
}

.admin-body{
	display: flex;
	margin-top: 3em;
}

.admin-controls{
	margin-left:16em;
	display: flex;
	height: 100%;
	position: fixed;
	top: 3em;
	overflow-y: scroll;
}

.admin-table-container{
	flex: 2;
}

.admin-detail-container{
	flex: 1;
}

.admin-detail{

}

.app-title{
	color: #F3F3F3;
	width:80%;
	margin: auto;
	height: 3em;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
}
.app-title-main{
	font-size:xx-large;
	font-weight:bold;
}
.catalog-text{
	font-size: 20px;
}

.grow {
	transition: all .2s ease-in-out;
}

.grow:hover {
	transform: scale(1.1);
}

/* Handle small screens*/
@media only screen and (max-width: 800px) {
	.app-title{
		margin-top: 10px;
	}
	.app-title-main{
		font-size:large;
		font-weight:bold;
	}
	.catalog-text{
		font-size: 10px;
	}
}

.backdrop{
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	background: rgba(0,0,0,0.7);
}

.btn{
	color: #932432 !important;
	background-color: #F3F3F3 !important;
}

.btn-suggest{
	color: #FFFFFF !important;
	background-color: #3b5fcb !important;
	border: none;
	border-radius: 5px;
	margin-bottom:15px;
}

.btn-visit-site{
	display: flex;
	justify-content: space-around;
	margin-top: 1em;
}

.clear{
	clear: both;
}

.tool{
	background: white;
	background-clip: padding-box;
	padding: 1em;
	margin: 1em;
	width: 20em;
	border-radius: 10px;
}

/*Makes it so that the whole thing is clickable*/
.tool>div{
	width:100%;
	height: 100%;
}

.cards-grid{
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	background-color: #3b5fcb;
}

.cards-grid-box{
	display: flex;
	overflow: hidden;
}

.cards-header{
	height: 3em;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
}


.tool-header{
	font-weight: bold;
	display: block;
}

.cards-list{
	background-color: #3b5fcb;

	/*This is to show the filters list*/
	padding-left: 16em;

	background-clip: content-box;
	width: 100%;
}

.mobile-cards-list{
	padding-left: 0px;
}

.mobile-grid{
	margin-top: 3em;
	overflow-y: scroll;
	position: fixed;
	top: 0;
	bottom: 0;
}

/* How cards look in the popup */
.tool-detail{
	padding: 1em;
}

.tool-title{
	width: 100%;
	max-height: 4em;
	text-align: center;
	font-size: 1.5em;
	font-weight: bold;
}


.tool-text{
	display: flex;
}

.category-text, .grades-text{
	width:50%;
	display: flex;
	flex-direction: column;
}


.filter-label{
	color:black;
	font-weight: bold;
}

.filters-list{
	position: fixed;
	display: inline-block;
	width:16em;
	overflow-y:scroll;
	padding: 1em;
	text-align:center;
	background-color: #F3F3F3;
}

.mobile-filter{
	width: 100%;
}

.main-container{
	display: flex;
}

.no-padding{
	padding:none;
}

.popup-overlay {
	border: 5px black;
	border-radius: 5px;
	background: #ffffff;
	width:80%;
	height: 85%;
	margin:auto;
	z-index: 1000;
	overflow-y: scroll;
}
.popup-content{
	color: black;
	padding:2em;
	text-align: left;
	height: 100%;
	background: #ffffff;
	z-index: 1000;
}
.popup-close{
	float: right;
	margin: 5px;
	z-index: 1000;
	display: block;
}

.suggest-table{
	width: 100%;
}

.text-left{
	text-align: left;
}
.text-center{
	text-align: center;
}
.text-right{
	text-align: right;
}

.title-image{
	max-width: 50%;
	display: inline;
	max-height: 200px;
	margin: auto;
}

.action-image{
	max-width: 100%;
	max-height: 300px;
	margin: auto;
	display: block;
}

.popup-images{
	display: flex;
	width: 100%;
}

.popup-images:after{
	content:"";
	display:block;
	clear:both;
}

.thumbnail-image{
	max-height: 6em;
	max-width: 80%;
	margin: auto;
	display: block;
}

.title-favicon-container{
	height: 100%;
}
.title-favicon{
	height: 100%;
}

/*------------*/
/*Burger Button Styles*/
/*------------*/
/* Position and sizing of burger button */

.bm-burger-button {
  position: fixed;
  width: 18px;
  height: 15px;
  left: 18px;
  top: 18px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

/* About page */
.about-page table {
	text-align: left;
	padding:  0px;
	border-spacing: 0px;
	width:  80%;
	position:  relative;
	margin: 0px auto;
	border: 1px solid white;
}
.about-page table td:nth-child(1) {
	padding:  0px 10px;
}
.about-page table td:nth-child(1n+1) {
	padding-bottom: .5em;
}
.about-page th {
	color:  white;
	font-weight: bolder;
	font-size: 2.5em;
	background-color: #3b5fcb;
	padding: .25em .5em;
}
.about-page caption {
	font-style: italic;
	color: white;
}

.about-page td{
	font-size: 1.8em;
	color: white;
}

.about-page tr{
	border: 1px solid white;
}
.about-page h1 {
	text-align: center;
}
/*classes*/
.top {
	border-top: 3px solid white;
}
.bottom {
	border-bottom: 1px solid white;
}

.about-page{
	margin: auto;
	width: 85%;
}

.about-header{
	display: flex;
}

.about-header::after {
  flex: 1; content: '';
}

.catalog-link{
	flex: 1;
	font-size: small;
	margin-bottom: 1em;
	font-style: italic;
}
.catalog-link a{
	color: white;
}

.catalog-link a:hover{
	color: yellow;
}

.about-header-text{
	flex: 1;
}

@media only screen and (max-width: 800px) {
	.about-header-text h1{
		font-size: large;
		width: 80%;
		margin: auto;
	}

	.about-page{
		font-size: medium;
	}

	.about-page th{
		font-size: medium;
	}

	.about-page td{
		font-size: medium;
	}

	.about-header{
		flex-wrap: wrap;
	}

	.catalog-link{
		flex: 0 0 100%;
		margin-right: auto;
		display: block;
		text-align: left;
	}

	.about-header-text{
		flex: 0 0 100%;
		margin: auto;
		text-align: center;
	}

	.about-page table{
		width: 100%;
		overflow-x: auto;
		display: block;
	}
}

.background{
	min-width:100%;
	min-height: 100%;
	height: auto !important;
	height: 100%;
	width: 100%;
	background-color: rgb(59, 95, 203);
	color: white;
}

/*Tool upload*/
.tool-upload-header{
	margin: auto;
	display: flex;
	justify-content: center;
	font-size: xx-large;
	font-weight: bold;
}

.tool-upload-container{
	display: flex;
	justify-content: center;
}

.tool-upload-import-result{
	display: flex;
	justify-content: center;
}
